"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var rightArrowPath = "./static/img/set_dir.png";
var downArrowPath = "./static/img/set_down.png";
var list = document.querySelectorAll(".list");
var menuMobile = document.querySelector("#menu-mobile");
var navbar = document.querySelector("#navbar");
var onLoad = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        attachSlideToAnchorElements();
        attachMenuMobileEvent();
        return [2];
    });
}); };
var attachSlideToAnchorElements = function () {
    list.forEach(function (item) { return item.addEventListener('click', function (ev) { handleMenuSlide(item, ev); }); });
};
var attachMenuMobileEvent = function () {
    menuMobile === null || menuMobile === void 0 ? void 0 : menuMobile.addEventListener('click', showMobileMenu);
};
var handleMenuSlide = function (item, event) {
    var _a, _b;
    event.stopPropagation();
    var image = item.querySelector(".image");
    if (item.classList.contains("active")) {
        item.classList.remove("active");
        if (image != null)
            configureMenuImage(image, ["h-2", "w-1"], ["h-1", "w-2"], rightArrowPath);
    }
    else {
        if ((_b = (_a = item.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.classList.contains("active")) {
            item.classList.add("active");
            if (image != null)
                configureMenuImage(image, ["h-1", "w-2"], ["h-2", "w-1"], downArrowPath);
        }
        else {
            for (var i = 0; i < list.length; i++) {
                list[i].classList.remove("active");
            }
        }
        item.classList.add("active");
        if (image != null)
            configureMenuImage(image, ["h-1", "w-2"], ["h-2", "w-1"], downArrowPath);
    }
};
var slide = function (item) {
    item.classList.toggle('hide');
};
var configureMenuImage = function (image, classesToAdd, classesToRemove, imagePath) {
    image.src = imagePath;
    classesToRemove.forEach(function (c) { return image.classList.remove(c); });
    classesToAdd.forEach(function (c) { return image.classList.add(c); });
};
var showMobileMenu = function () {
    navbar === null || navbar === void 0 ? void 0 : navbar.classList.toggle("-translate-x-full");
};
window.addEventListener('load', onLoad);
